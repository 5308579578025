.navbar img {
	width: 37px;
	cursor: pointer;
	margin-right: 1.75rem;
}

.logout {
	cursor: pointer;
	font-size: 13px;
    background: black;
    color: white;
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: 700;
}
.logout:hover {
	opacity: 0.8;
}

nav .navbar-nav .nav-item {
	margin-right: 30px;
}

nav .navbar-nav .nav-item .nav-link {
	color: black;
}

nav .navbar-nav .nav-item a:hover, nav .navbar-nav .nav-item a.active {
    font-weight: 700;
    color: #6bf2c1;
	background: #fff;
	text-decoration: none;
}

nav .navbar-nav .nav-item a:before {
    display: block;
    content: attr(title);
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.dropdown .nav-link:hover {
	font-weight: 400!important;
	color: black!important;
}

.profile-icon {
	font-size: 18px;
}