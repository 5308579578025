@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,400;0,600;0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300;400;500;600&family=Lora:wght@400;500;600;700&display=swap');

html,
body,
#root,
.app {
	height: 100%;
	width: 100%;
	font-family: 'Libre Franklin', sans-serif;
}

body {
	background-color: #ededed;
}

a {
	color: black;
	text-decoration: none;
	font-size: 14px;
	cursor: pointer;
}

a:hover {
	text-decoration: underline;
	color: black;
}

h1, h2, h3, h4, h5, h6 {
	font-family: "Lora";
}

.bold {
	font-weight: 700;
}

.text-right {
	text-align: right;
}

.fullpage {
    min-height: calc(100vh - 51.8px);
}

.link {
	cursor: pointer;
}
.link a {
	color: #0f5132!important;
	font-weight: bold;
	text-decoration: underline;
}

.btn-primary:hover {
	text-decoration: none;
}

.link-form {
	text-decoration: underline;
	color: #0f5132;
	font-size: 16px;
}

.patch-check {
	color: #6bf2c1;
	margin-right: 3px;
}

.bg-revised {
	background-color: #d8f9ea;
}

.btn-secondary-brand {
	background-color: white;
	border: 2px solid#6bf2c1!important;
	color: black;
	font-weight: 600;
	text-transform: uppercase;
}
.btn-secondary-brand:hover {
	background-color: #6bf2c1;
	border: 2px solid#6bf2c1!important;
}

.pagination .page-link.active {
	background: #6bf2c1;
	border-color: #6bf2c1;
}

.pagination .page-link {
	color: black;
}

.table-link {
	font-size: 16px;
}

.text-truncate {
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
}

.suppliers td.text-nowrap a {
	white-space: nowrap;
    overflow: hidden;
    width: 270px;
    text-overflow: ellipsis;
}
.approved-btn {
	font-size: 25px;
}
.search-admin .lupa{
	background: black;
    color: white;
    padding: 5px 10px;
}

.text-bg-danger-red {
	background-color: #dc3545;
}
.search-admin .form-control {
	background: white;
    border-radius: 0px;
    border: none;
}

.alert-link {
	font-weight: bold;
	text-decoration: underline;
	font-size: 14px;
}
