.logo-login {
	width: 280px;
}

.mr-link {
	margin-right: 3rem;
}

.text-gdpr-register {
    font-size: 10px;
}

.text-muted {
	font-size: 14px;
}
.fixed-width {
	max-width: 40vw;
	width: 40vw;
}

.form-control {
	background-color: #ededed;
}