.thead-dark {
	background-color: black;
	color: white;
}

table thead tr th, table tbody tr td {
	padding: 1.25rem 1rem!important;
}

.text-bg-success {
    background-color: #16697A!important;
}

.text-bg-danger {
    color: black!important;
    background-color: #DFDFDF!important;
}

.logo-money {
	width: 1.3rem;
	margin-right: 5px;
}

.ver-button {
	border: 2px solid #6bf2c1;
    padding: 5px 14px;
    border-radius: 5px;
	font-weight: 700;
}
.ver-button:hover {
	text-decoration: none;
	background-color: #6bf2c1;
}