.breadcrumb-item+.breadcrumb-item::before {
    content: url('../assets/img/arrow-breadcrumb.svg');
}

.breadcrumb li {
	font-size: 14px;
}
.breadcrumb .active {
	font-weight: 700;
	color: black;
}