.btn-primary {
	background-color: #6bf2c1;
	border-color: #6bf2c1;
	color: #000;
	font-weight: 700;
	text-transform: uppercase;
	border: 2px solid #6bf2c1;
}

.btn-primary:hover {
    background-color: #fff;
    border-color: #6bf2c1;
	color: #000;
	border: 2px solid #6bf2c1;
}